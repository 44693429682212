.Profile{
    display: flex;
    align-items: center;
    padding: 9px;
    background-color: #E1E1E1;
    width: 573px;
    display: flex;
    justify-content: space-between;
}
.Profile__info{
    display: flex;
    align-items: center;
}
.profile__img{
    width: 47px;
    height: 47px;
    border-radius: 50%;
}

.profile__nickname {
    margin-left: 21px;
    color: #6A7480; 
    font-weight: 400;
    font-size: 18px;
    
}

.profile__nickname:hover {
    color: #3369F3; 
    
    
}


.headphones, .microphone{
    width: 20px;
    height: 20px;
    margin-left: 30px;
}


.customOverlay {
    background-color: rgba(0, 0, 0, 0.5); 
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex; 
    align-items: center; 
    justify-content: center; 
    z-index: 1000; /* Поверх других элементов */
  }
  
  .customContent {
    background-color: #333;
    width: 530px; /* это */
    height: 675px; 
    padding: 20px 35px;
    border-radius: 10px;
    outline: none;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    margin: 0 auto;
    
  }
  