.contact-item {
    display: flex; 
    align-self: center; 
    padding: 12px 7px 5px;
    font-size: 16px;
    font-weight: 400;
  }
  
  .contact-item__image {
    width: 47px;
    height: 47px;
    border-radius: 50%; 
    margin-right: 10px; 
  }

  .contact-item__name{
    margin-bottom: 2px;
  }
  
  .contact-item__last-message{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  

  .contact-item__last-message {
    white-space: nowrap;          
    overflow: hidden;            
    text-overflow: ellipsis;      
    max-width: 500px;             
    display: inline-block;     
    opacity: 0.6;   
  }

  .contact-message-date{
    opacity: 0.6;
    white-space: nowrap;          
    overflow: hidden;            
    text-overflow: ellipsis;      
    max-width: 500px;             
    display: inline-block; 
    margin-left: 100px;
    font-size: 12px; 
 
  }

  .contact-item__delete {
    margin-right: 25px;
    margin-left: auto;
    position: relative;
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
  
  .contact-item__delete::before,
  .contact-item__delete::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 2px;
    background-color: #333; 
    transform-origin: center;
  }
  
  .contact-item__delete::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  
  .contact-item__delete::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  
  .contact-item__delete:hover::before,
  .contact-item__delete:hover::after {
    background-color: #ff0000; 
  }
  .blue-text {
    color: blue;
  }
  