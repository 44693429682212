.register-label {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.register-wrapper {
    width: 332px; 
    height: 51px;
    background-color: rgba(238, 238, 238, 1);
    border-radius: 8px;
    margin-top: 10px;
}

.register-input {
    padding: 14px 23px 14px 23px;
    width: 100%;
}
