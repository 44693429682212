
  .upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px; /* это */
    margin-top: 10px; /* это */
  }
  
  .upload-button {
    cursor: pointer;
  }
  
  .upload-input {
    display: none;
  }
  
  .image-upload-wrapper {
    width: 130px; /* это */
    height: 130px; /* это */
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .upload-icon {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .upload-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 18px; /* это */
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;
  }
  
  .image-upload-wrapper:hover .upload-icon {
    opacity: 0.5;
  }
  
  .image-upload-wrapper:hover .upload-text {
    opacity: 1;
    visibility: visible;
  }
  
  .profile__username{
    font-size: 20px; /* это */
    font-weight: 700;/* это */
    line-height: 16px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #E2E2E4;
    margin-bottom: 40px;
}

.profile-modal{
    display: flex;
    flex-direction: column;
    justify-content: center; 
  }
  
  .profile_logout {

    color: white;
    padding: 20px 35px;
    background-color: #3369F3;
    border-radius: 8px;
    margin: 20px auto;
    width: 160px;

}