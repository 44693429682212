/* videoContainer.css */
.call-component {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 9999;
  }
  
  .video-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
  }

  .call-component button {
    margin-top: 70px;
    font-weight: 600;
    font-size: 18px;
    padding: 13px 80px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  /* Стиль кнопки начала звонка */
  .call-component .start-call-button {
    color: white;
    background-color: rgba(30, 144, 255, 1); /* Синий цвет */
    border: 1px solid rgba(30, 144, 255, 1);
  }
  
  .call-component .start-call-button:hover {
    background-color: rgba(0, 105, 217, 1); /* Более тёмный синий */
    color: white;
  }
  
  /* Стиль кнопки завершения звонка */
  .call-component .end-call-button {
    color: white;
    background-color: rgba(255, 69, 58, 1); /* Красный цвет */
    border: 1px solid rgba(255, 69, 58, 1);
  }
  
  .call-component .end-call-button:hover {
    background-color: rgba(204, 45, 36, 1); /* Более тёмный красный */
    color: white;
  }
  
.remote-video {
  width: 50%;
  height: 100%;
  border-radius: 10px;
  background: black;
}

.local-video {
  width: 50%;
  height: 100;
  border-radius: 10px;
  background: black;
}