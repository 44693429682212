
.friend {
    width: 100% ; /* Полная ширина */
    background-color: red;
    max-height: 50px;
  }
  
  .friend__wrapper {
    width: 100%; /* Растягиваем на всю ширину */
    box-sizing: border-box; /* Учитываем padding в ширине */
    background-color: white;
    padding: 12px 17px;
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Равномерное распределение элементов */
  }

.frind-icon{
    width: 22px;
    height: 22px;
}

.friend-box{
    display: flex;
    align-items: center;
}
.dash{
    width: 1px;
    height: 24px;
    background-color: grey;
    margin-left:  16px;

}

.friend-box__title{
    font-size: 16px;
    font-weight: 600;
    margin-left: 9px;
}
.friend__pages{
    margin-left: 23px;
    display: flex;
    gap: 11px;
    align-items: center;

}

.friend-page {
    font-size: 16px;
    padding: 3px 7px;
    transition: background-color 0.3s ease;
  }
  
.friend-page:hover {
    background-color: #DEE0E4;
}
  

.number__pending {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: red;
    margin: 0px 10px;
}
  
.number__pending span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: white;
}

.friends__button{
    background-color: #3BA55D;
    padding: 3px 7px;
    color: white;
    border-radius: 4px;
    margin-left: 20px;
}