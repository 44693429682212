.message-item {
    margin-bottom: 13px;
    display: flex;
    flex-direction: column; 
    padding: 11px;
    border-radius: 10px;
    max-width: 400px;
    position: relative; 
  }
  
  .user-message {
    margin-left: auto; 
  }
  
  .other-message {
    max-width: 400px;
  }
  
  .message-content {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 8px;
    background-color: #ebebeb;
    word-wrap: break-word; 
    max-width: 100%;
    position: relative; 
  }
  
  
  .short-message-date,
  .long-message-date {
    position: absolute;
    bottom: 5px; 
    right: 5px; 
    font-size: 12px;
    color: #888;
    text-align: right; 
    
  }
  
 
  .short-message-date {
    text-align: right;
    font-size: 12px;
    color: #888;
    
  }
  
  .message-date {
    font-size: 12px;
    color: #888;
    margin-top: 5px; /* Отступ сверху для даты */
    text-align: right; /* Выравнивание по правому краю */
    position: relative; 
  }
  

  .message-header {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .message-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px; 
  }
  
  .message-username {
    font-size: 14px;
    font-weight: 600;
  }
  
  
  .other-message .message-header {
    flex-direction: row; 
  }
  
  .user-message .message-header {
    justify-content: flex-end; 
    margin-left: 0;
  }

  .message-attachment {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
  
  .attachment-image {
    max-width: 100%;
    max-height: 200px;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  