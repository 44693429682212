

.register {
    display: flex;
    justify-content: center;
    align-items: center; 
    height: 100vh; 
    background-color: #F0F0F0;
}

.container-register{
    margin: auto auto;
    max-width: 1440px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    border-radius: 20px; 
    box-shadow: 28px 0px 50.49px 0px rgba(0, 0, 0, 0.17);
}

.register__left{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 61px;
    width: 836px;

}

.register__logo{
    margin-top: 39px;
}

.register__caption{
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.register-form{
    display: grid;
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: repeat(4, auto); 
    column-gap: 52px; 
    row-gap: 27px; 
    margin-top: 81px;
}

.register-form__button{
    align-self: flex-start;
    width: 214px;
    height: 50px;
    border-radius: 8px;
    background-color:rgba(30, 39, 114, 1);
    color: rgba(255, 255, 255, 1);
    box-shadow: 0px 12.12px 24.23px 0px rgba(1, 11, 253, 0.12);
    margin: 40px 0 161px;
    font-weight: 600;
    font-size: 16px;
}

.profile__icon{
    margin-right: 121px;
}


@media (max-width: 768px) {
    
    .container-register{
        width: 100%;
        justify-content:center;
    }
    .register-form{
        display: block;
    }
    .register-form__button{
        width:100%;
    }
    .register{
        display: block;
    }
}