.list-messages {
  display: flex;
  flex-direction: column;
  height: 95vh; /* Занимает всю высоту экрана */
  background-color: #EAEAEA;
}
  .list-messages__header {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;

    width: 100%;
    border-bottom: 1px solid #EAEAEA;
  }
  .list-messages__interlocutor{
    display: flex;
    align-items: center;
  }
  
  .message__wrapper {
    flex-grow: 1; 
    overflow-y: auto; /* Позволяет прокручивать содержимое */
    background-color: white;
    display: flex;
    flex-direction: column; 
    justify-content: flex-start; 
    padding: 20px;
    height: 100%; 
    box-sizing: border-box; 
  }
  

  
  .list-messages__choice {
    width: 40px;
    height: 40px;
    background-color: #F5F5F5;
    border-radius: 50%;
    background-image: url('./../../../public/points.png');
    background-position: center; 
    background-repeat: no-repeat; 
  }
  
  .list-messages__avatar {
    width: 40px;
    height: 40px;
  }
  
  .list-messages__name {

    font-size: 18px;
    font-weight: 600;
    line-height: 13px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-left: 18px;
  }
  .list-messages__footer {
    padding: 11px 20px;
    border-top: 1px solid #EAEAEA;
    background-color: white;
    width: 100%;
    display: flex;
    align-items: center;
  }
  
  .list-messages__upload {
    width: 32px;
    height: 32px;
    margin-right: 10px;
    background-image: url('./../../../public/upload.png');
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .list-messages__input {
    flex-grow: 1; 
    padding: 10px 20px;
    border: 1px solid #EAEAEA;
    border-radius: 20px;
 
    font-size: 16px;
    background-color: #EFEFEF;
    color: #999999;
  }
  
  .list-messages__send {
    width: 28px;
    height: 28px;
    background-image: url('./../../../public/send.png');
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    background-color: #3369F3;
    margin-left: 15px;
  }
  .attachment-preview {
    display: flex;
    flex-wrap: nowrap; /* Запрещаем перенос элементов на следующую строку */
    gap: 10px; /* Расстояние между превью */
    margin-bottom: 10px; /* Отступ от превью до поля ввода */
    overflow-x: auto; /* Если превью не помещаются, показываем скролл */
  }
  
  .attachment-thumbnail {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 8px;
    overflow: hidden;
    margin-left: 7px;
  }
  
  .attachment-thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .attachment-thumbnail button {
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    font-size: 14px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .attached{
    display: flex;
    padding: 0 20px;
  }

  .attached-forward{
    background-image: url('./../../../public/forward.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
.list-messages__buttons{
  display: flex;
  align-items: center;
}
    
.call{
  width: 30px;
  height: 30px;
  background-image: url('./../../../public/icons8-telefon-48.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 15px;

}