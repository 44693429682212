.chatsContent{
    background-color: black;
}

.chatsOverlay{
    background-color: rgba(0, 0, 0, 0.5); 
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex; 
    align-items: center; 
    justify-content: center; 
    z-index: 1000; 
}

.chatsContent{
    background-color: #333;
    width: 330px; /* это */
    height: 215px; 
    padding: 20px 12px;
    border-radius: 10px;
    outline: none;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    margin: 0 auto;
}

.chat-create__title {
    font-weight: 400; 
    font-size: 19px; 
    line-height: 24px; 
    margin-bottom: 21px;
    margin-top: 10px;
    margin-left: 15px;
    color: white;
    font-family: 'Whitney', sans-serif; 
    text-align: left;
  }
  
  .chat-create__input {
    width: 100%;
    padding: 14px 23px; 
    margin-top: 10px;
    border-radius: 8px; 
    background-color: rgb(22, 21, 21);
    font-size: 16px; 
    font-weight: 400;
    line-height: 24px; 
    color: white;
    border: none;
    outline: none;
  }
  
  .chat-create__modal-content {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  

  
  .chat-create__buttons {
    display: flex;
    justify-content: space-around;
  }
  

  .button-save {
    padding: 15px 20px;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;

    font-size: 16px;
    font-weight: 600;
    line-height: 11.53px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .button-cancel{
    font-size: 16px;
    font-weight: 600;
    line-height: 11.53px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  
  .button-save {
    background-color: #007bff;
  }
  
  .button-cancel:hover,
  .button-save:hover {
    opacity: 0.9;
  }
  
  