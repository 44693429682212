/* Стили для полей профиля */
.profile-field {
    margin: 7px 0;
    padding-top: 7px;
}

.profile-field__data {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
    padding: 0 7px;
    color: white;
}

.profile__line {
    height: 1px;
    background-color: grey;
}

.profile-field:hover {
    background-color: rgb(32, 32, 32);
}

.profile-modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050; 
}

.profile-modal__modal {
    background-color: rgb(51, 51, 51);
    padding: 12px 12px 12px 14px;
    border-radius: 4px;
    width: 330px;
    z-index: 1060; 
    position: relative;
    border: 1px solid rgb(66, 66, 66);
}

.profile-field__modal-content {
    margin-bottom: 20px;
}

.profile-field__input {
    width: 100%;
    padding: 8px;
    margin-top: 10px;
    border-radius: 4px;
    background-color: rgb(22, 21, 21);
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: white;
    margin-bottom: 11px;
}

.profile-edit__title{
    margin-bottom: 21px;
    font-size: 19px;
    font-weight: 500;
    line-height: 20.55px;
    text-align: left;
    color: #FFFFFF;
}

.edit__button {
    display: flex;
    justify-content: flex-end; 
    gap: 10px; 
    margin-top: 20px; 
}

.button-cancel {
    padding: 7px 20px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    transition: background-color 0.3s;
}


.button-save {
    padding: 7px 20px;
    background-color: #3369F3; 
    color: white;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button-save:hover {
    background-color: #295acb; 
}
