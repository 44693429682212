

  
.login {
    display: flex;
    justify-content: center;
    align-items: center; 
    height: 100vh; 
    background-color: #F0F0F0;
  }

.login__container{
    margin: auto auto;
    max-width: 1440px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    border-radius: 20px 20px 20px 20px;
}

.login__right{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 517px;
    padding: 0 15px;
    height: 900px;
}

.login__container {
    margin: auto; 
    max-width: 1440px; 
    background-color: white; 
    display: flex; 
    justify-content: space-between; 
    box-shadow: -28px 0px 50.49px 0px rgba(0, 0, 0, 0.05);
}

.login__left {
    background-color: rgb(104, 180, 116);
    border-top-left-radius: 20px; 
    border-bottom-left-radius: 20px; 
    
}

.login__right {
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    width: 517px; 
    padding: 0 15px; 
    height: 900px; 
    border-top-right-radius: 20px; 
    border-bottom-right-radius: 20px; 
    background-color: #f9f9f9; 
}

.login__left{
    background-color: rgb(104, 180, 116); 
}

.login__heading{
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #555555;

}

.login__img{
    margin-top: 101px
}

.form{
    margin-top: 68px;
}

.email-input,
.password-input {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.email-input__label,
.password-input__label {
    margin-bottom: 8px;
    font-size: 16px;
    color: #333;
}

.email-input__container,
.password-input__container {
    display: flex;
    align-items: center;
    width: 100%;
}

.email-input__field,
.password-input__field {
    width: 380px;
    height: 50px;
    padding: 14px 20px 15px 20px;
    font-size: 14px;
    border-radius: 8px 0 0 8px;
    background-color: #EEEEEE;
    outline: none;
    color: #555555;
}

.email-input__field:focus,
.password-input__field:focus {
    border-color: #aaa;
}

.email-input__icon-wrapper,
.password-input__icon-wrapper {
    height: 50px;
    width: 50px;
    background-color: #1a237e;
    padding: 10px;
    border-radius: 0 8px 8px 0;
}

.email-input__icon,
.password-input__icon {
    width: 28px;
    height: 28px;
}

.password-input{
    margin-top: 24px;
}

.login__forgot{
    margin: 23px 0 38px;
    text-align: right;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: #1E2772;
    text-decoration: underline;
}

.login__button{
    width: 430px;
    height: 50px;
    border-radius: 8px;
    background-color: rgba(30, 39, 114, 1);
    box-shadow: 0px 12.12px 24.23px 0px rgba(1, 11, 253, 0.12);


    color: white;
}

.login__line-wrapper{
    width: 430px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.login__line-or{
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: rgba(194, 194, 194, 1);

}
.line {
    border-bottom: 1px solid rgba(194, 194, 194, 1);
    margin: 48px 0;
    width: 184px;
}

.signup__button {
    font-weight: 600;
    font-size: 16px;
    padding: 13px 166px;
    color: rgba(30, 39, 114, 1);
    border-radius: 8px;
    border: 1px solid rgba(30, 39, 114, 1);
    background-color: transparent; /* Фоновый цвет по умолчанию */
    transition: background-color 0.5s ease, color 0.2s ease; /* Увеличиваем время перехода до 0.5 секунд */
}

/* Медиазапрос для телефонов (ширина экрана до 768px) */
@media (max-width: 768px) {
    .login{
        display: block;
    }
    .login__container{
        width: 100%;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .login{
        display: block;
    }
    .login__container {
        width: 50%; 
        border-radius: 10px; 
    }
}