.main__wrapper {
  display: flex;
  height: 100vh; 
  margin: 0;
}

.channels__wrapper {
  display: flex;
  flex-direction: column;
  background-color: #333;
  width: 70px; 
  box-sizing: border-box;
}

.sidebar-button {
  width: 48px;
  height: 48px;
  margin: 0 auto;
  background-color: white;
  border-radius: 50%;
  background-image: url('../../../public/Plus (1).png');
  background-position: center;
  background-repeat: no-repeat;
}

.content {
  flex: 1; 
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
}

.content__wrapper {
  display: flex; 
  flex: 1; 
}

.content__left {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; 
  background-color: #2c2f33;
  width: 573px; /* важно */
  height: 100%; 
  box-sizing: border-box;

}

.content__right {
  flex: 1; 


}

.content__left-profile {
  display: flex;
  flex-direction: column; /* Вертикальное размещение */
  height: 100%; /* Занимает всю высоту */
  background-color: #F2F3F5;
}

.chats__add {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 23px;
}

.chats__list {
  flex-grow: 1; /* Заполняет оставшееся пространство */
  background-color: #f0f0f0;

  overflow-y: auto;
}

.chats__create{
  margin-right: 9px;
  background-image: url('../../../public/Plus.png');
  width: 15px;
  height: 15px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.ctats__title{
  color:#7d8085;
  font-size: 13px;
}

